import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AxiosResponse, AxiosError } from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ButtonPanel } from "../../../components/button-panel";
import { ErrorPanel } from "../../../components/error-panel";
import { HeavyCard } from "../../../components/heavy-card";
import { HttpErrorPanel } from "../../../components/http-error";
import { Loading } from "../../../components/loading";
import { RESPONSE_TYPE } from "../../../const/vote";
import { AuthContext } from "../../../context/auth.provider";
import { useLabel } from "../../../context/label-cache.provider";
import { BallotOptInPreference, CANDIDATE_DISPLAY_FORMAT, IBallotResponse } from "../../../domain/ballot";
import { CONSUMER_TYPE } from "../../../domain/consumer";
import { BallotProvider, BallotContext } from "../ballot/ballot.context";
import { sortResult } from "../util";
import { BallotPageContext } from "./ballot-page.context";
import { useFetchState } from "../../../context/api.provider";
import { useNavigate } from "react-router-dom";
import { ElectionContext } from "../election.context";
import { CandidateImage } from "../ballot/preference/candidate-image";

export const BallotPageReview = () => {
  const { ballotPage, ballots, ballotPageResponse } =
    useContext(BallotPageContext);
  const { consumer } = useContext(AuthContext);
  const { refreshElectionState } = useContext(ElectionContext);
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [result, postResponse] = useFetchState();
  const done = useMemo(() => !!result && result.status === 200, [result]);
  const allowedToSubmit = useMemo(() => {
    return consumer && consumer.type === CONSUMER_TYPE.VOTER;
  }, [consumer]);
  useEffect(() => {
    if (done) {
      setTimeout(() => navigate(`/election/${ballotPage.election_id}`), 1000);
    }
  }, [done]);

  if (!consumer) {
    return <ErrorPanel text="Must have consumer" source="ReviewPage" />;
  }

  const handleSubmit = () => {
    setSubmitting(true);
    postResponse({
      url: `/ballot_pages/${ballotPage.id}/response`,
      method: "post",
      data: ballotPageResponse,
    }).then(refreshElectionState);
    // setTimeout(() => submit().then((r) => setResult(r)), 200);
  };

  const handleBack = () => {
    setSubmitting(false);
  };

  if (result && result.status !== 200 && "response" in result) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Please review your submission before submitting. After submission
            you will not be able to change your vote.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item xs={12}>
          <HttpErrorPanel
            text="Submit failed"
            source="ReviewPage"
            axiosResponse={result}
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (submitting || done) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Thank you for participating in this election.</Typography>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Loading source="Review" waitingFor={{ Submit: done }} />
          {done ? (
            <Typography
              color="green"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              Submitted!
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" >{ballotPage.label}</Typography>
        <Typography variant="h5" mb={4}>Submission Review</Typography>
      </Grid>
      <Grid item xs={12}>

        {ballots.map((b) => (
          <BallotProvider ballot={b} key={b.id}>
            <BallotReview />
          </BallotProvider>
        ))}
      </Grid>
      <Grid item xs={12} mt={3}>
        <ButtonPanel
          color={allowedToSubmit ? undefined : "warning"}
          title={
            allowedToSubmit ? undefined : "Cannot submit votes in preview mode."
          }
          back={{
            to: `/election/${ballotPage.election_id}/ballot_page/${ballotPage.id}/vote`,
          }}
          next={{
            label: "Submit",
            icon: "submit",
            onClick: handleSubmit,
            disabled: !allowedToSubmit,
          }}
        />
      </Grid>
    </Grid>
  );
};

const BallotReview = () => {
  const { ballot, ballotResponse } = useContext(BallotContext);
  return (
    <HeavyCard title={ballot.label} sx={{ mb: 3 }}>

      {ballotResponse && ballotResponse.vote ? (
        <ResponseReview
          key={ballotResponse.ballot_id}
          response={ballotResponse}
          candidate_display_format={ballot.candidate_display_format}
        />
      ) : (
        <Typography color="error">ERROR EMPTY RESPONSE</Typography>
      )}
    </HeavyCard>
  );
};

interface ResponseOptInReviewProps {
  require_opt_in: BallotOptInPreference;
  opt_in_details: any;
}
const ResponseOptInReview = ({
  require_opt_in,
  opt_in_details,
}: ResponseOptInReviewProps) => {
  return (
    <Box>
      <Box>{require_opt_in.title}</Box>
      <Box>{JSON.stringify(opt_in_details)}</Box>
    </Box>
  );
};

interface ResponseReviewRankingProps {
  response: IBallotResponse;
  candidate_display_format?: CANDIDATE_DISPLAY_FORMAT
}
const ResponseReview = ({ response, candidate_display_format }: ResponseReviewRankingProps) => {
  const { type, vote } = response;
  switch (type) {
    case RESPONSE_TYPE.YES_NO:
      return (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell align="center">Response</TableCell>
              <TableCell>{vote}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    case RESPONSE_TYPE.PREFERENCE:
      const ranking = Object.values(vote).filter((v) => v.index !== null);
      ranking.sort(sortResult);
      return (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" width={80}>
                Preference
              </TableCell>
              <TableCell
                colSpan={candidate_display_format === CANDIDATE_DISPLAY_FORMAT.IMAGE_PRIORITY ? 2 : undefined}>
                Candidate
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {ranking.map((r, i) => (
              <TableRow key={i}>
                <TableCell align="center">{r.index}</TableCell>
                <TableCell>
                  <CandidateName candidate_id={r.id} />
                </TableCell>
                {candidate_display_format === CANDIDATE_DISPLAY_FORMAT.IMAGE_PRIORITY ?
                  <TableCell><CandidateImage id={r.id} sm /></TableCell>
                  : null
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
  }
  return null;
};

interface CandidateNameProps {
  candidate_id: string;
}
const CandidateName = ({ candidate_id }: CandidateNameProps) => {
  const label = useLabel("candidate", candidate_id);

  return <Typography>{label}</Typography>;
};
