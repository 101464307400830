import { API_BASE } from "../../../../const/config"

interface CandidateImageProps {
    id: string;
    lg?: boolean;
    sm?: boolean;
}
export const CandidateImage = ({ id, lg, sm }: CandidateImageProps) => {
    const im = sm ? 'image_sm' : 'image_lg';
    const url = `${API_BASE}/assets/candidates/${id}/${im}`
    return (
        <img style={{ maxWidth: "100%", maxHeight: "100%", border: '1px solid #E8e8e8', borderRadius: 3, objectFit: "contain" }} src={url} />
    )
}