import { useMemo } from "react";

export interface LogoProps {
    white?: boolean;
    lighter?: boolean;
    darker?: boolean;
}

export const Logo = ({ white, lighter, darker }: LogoProps) => {
    const url = useMemo(() => {
        if (white) return "/logo-white.png";
        if (lighter) return "/logo-trans-lighter.png";
        if (lighter) return "/logo-trans-darker.png";
        return "/logo-trans.png"
    }, [white, lighter, darker]);

    return <img src={url} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
}