import { useState, useContext, useCallback } from "react";
import { Loading } from "../../../../components/loading";
import { RESPONSE_TYPE } from "../../../../const/vote";
import { BallotContext } from "../ballot.context";
import { ICandidate } from "../../../../domain/candidate";
import { CheckBox, SquareOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

export const ResponseFirstPast = () => {
  const { ballotResponse, setBallotVote } = useContext(BallotContext);
  const { candidates } = useContext(BallotContext);
  const vote =
    ballotResponse && ballotResponse.type === RESPONSE_TYPE.FIRST_PAST
      ? ballotResponse.vote
      : null;

  const handleClick = useCallback(
    (candidate: ICandidate) => {
      setBallotVote({
        type: RESPONSE_TYPE.FIRST_PAST,
        vote: {
          id: candidate.id,
          label: candidate.label,
        },
      });
    },
    [vote]
  );
  if (!candidates) {
    return <Loading source="ResponseFirstPast" />;
  }
  return (
    <>
      <List>
        {candidates.map((c, i) => (
          <ListItem
            key={c.id}
            sx={{
              cursor: "pointer",
              padding: "1px 10px",
              ":hover": {
                background: "#EEE",
              },
            }}
            onClick={(e) => handleClick(c)}
          >
            <ListItemIcon>
              {" "}
              <Checkbox
                checked={vote !== null && vote.id === c.id}
                sx={{ padding: "5px" }}
              />
            </ListItemIcon>
            <ListItemText primary={c.label} secondary={c.tag_line} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

interface CandidateProps {
  candidate: ICandidate;
  selected: boolean;
  onClick: (candidate: ICandidate) => void;
}

const Candidate = ({ candidate, selected, onClick }: CandidateProps) => {
  const handleClick = () => {
    onClick(candidate);
  };
  return (
    <Box onClick={handleClick}>
      <Grid container>
        <Grid item xs={2} sm={1}></Grid>
        <Grid
          item
          xs={10}
          sm={11}
          sx={{ display: "flex", alignContent: "center", alignItems: "center" }}
        >
          <Typography>{candidate.label}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
