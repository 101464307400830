import {
    Typography,
    Box,
    ListItem,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    List,
    TypographyProps,
    Theme,
    SxProps,
    Button,
} from "@mui/material";
import React, { useMemo, useCallback, useEffect, useState, PropsWithChildren } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ZoomInIcon from '@mui/icons-material/ZoomIn';

export interface LightBoxProps extends PropsWithChildren {
    small: React.ReactNode;
    sx?: SxProps<Theme>;
    icon?: JSX.Element;
    buttonProps?: SxProps<Theme>
    onLightBox?: (open: boolean) => void
}

export const LightBox = ({
    small,
    icon,
    sx,
    children,
    onLightBox
}: LightBoxProps) => {

    const handleSelect = useCallback((id: string) => {
        //navigate(showPath(RESOURCE.organisation, id));
        setOpen(false);
    }, []);

    const [open, setOpen] = useState(false);
    const handleOpen = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(true);
        onLightBox && onLightBox(true);
    }, [setOpen]);
    const handleClose = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(false);
        onLightBox && onLightBox(false);
    }, [setOpen]);
    const _icon = useMemo(() => icon || <ExpandMoreIcon />, [icon]);

    return (
        <Box
            padding="0"
            sx={{
                boxSizing: "border-box",
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                ...sx,
            }}
        >
            <Box sx={{
                cursor: 'pointer', ":hover": {
                    "::after": {
                        background: "rbga(0,0,0,0.3)"
                    }
                },
                position: 'relative',
                flexShrink: 1,
                display: 'flex',
                margin: 'auto',
                height: '100%',
            }}>
                {small}
                <IconButton onClick={handleOpen} size="large" sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    background: 'rgba(0,0,0,0.1)',
                    ":hover": {
                        background: 'rgba(0,0,0,0.3)'

                    }
                }}  >
                    <ZoomInIcon />
                </IconButton>
            </Box>

            {open ? (
                <Box
                    sx={{
                        zIndex: 5000,
                        position: "fixed",
                        top: "0",
                        left: 0,
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyItems: "center",
                        width: "100%",
                        height: "100%",
                        background: "rgba(0,0,0,0.3)",
                        maxWidth: "100vw"
                    }}
                    onClick={handleClose}

                >
                    <Box
                        sx={{
                            background: "#FFF",
                            padding: "10px",
                            boxShadow: 3,
                            boxSizing: "border-box",
                            maxWidth: "100%",
                            maxHeight: "100%"
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            ) : null}

        </Box>
    );
};
