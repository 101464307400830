import {
  Box,
  Divider,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BallotContext } from "../ballot.context";
import { Loading } from "../../../../components/loading";
import { RESPONSE_TYPE } from "../../../../const/vote";
import { ICandidate } from "../../../../domain/candidate";
import { CandidateRowLabel, CandidateRowImage } from "./candidate-row";
import { CANDIDATE_DISPLAY_FORMAT } from "../../../../domain/ballot";
export const PreferenceTextInterface = () => {
  const { ballotResponse, candidates, setCandidateRank, ballot } =
    useContext(BallotContext);

  const { candidate_display_format } = ballot;

  const vote =
    ballotResponse && ballotResponse.type === RESPONSE_TYPE.PREFERENCE
      ? ballotResponse.vote
      : null;

  const errors: { [id: string]: boolean } = useMemo(() => {
    if (!vote) {
      return {};
    }
    const e: any = {};
    const vals = Object.values(vote);
    for (const val of vals) {
      if (val !== null && val.index !== null) {
        if (candidates && val.index > candidates.length) {
          e[val.id] = true;
        }
        if (val.index <= 0) {
          e[val.id] = true;
        }
        if (vals.filter((v) => v.index === val.index).length > 1) {
          e[val.id] = true;
        }
      }
    }
    return e;
  }, [vote, candidates]);

  // const handleClick = (id: string) => {
  //   const rank = mapping[id];
  //   if (rank === undefined) {
  //     setRanking((_ranking) => [..._ranking, id]);
  //   } else {
  //     setRanking((_ranking) => _ranking.filter((r) => r !== id));
  //   }
  // };

  const handleInput = useCallback(
    (c: ICandidate, rank: number | null) => {
      setCandidateRank(c.id, rank, c.label);
    },
    [setCandidateRank]
  );

  console.log("vote", vote);
  const getRank = useCallback(
    (id: string) => (vote && vote[id] ? vote[id].index : null),
    [vote]
  );

  const ranks = useMemo(() => {
    if (!candidates) return [];

    return candidates.map((c) => getRank(c.id));
  }, [candidates, getRank]);

  console.log("render");
  useEffect(() => {
    console.log("ballotResponse updated");
  }, [ballotResponse]);
  useEffect(() => {
    console.log("vote updated");
  }, [vote]);
  useEffect(() => {
    console.log("getRank updated");
  }, [getRank]);
  useEffect(() => {
    console.log("ranks updated", ranks);
  }, [ranks]);

  const getNextNumber = useCallback(
    (start: number = 1) => {
      if (!candidates) {
        return 0;
      }
      const max = candidates.length;
      if (start === max) {
        if (max !== 1) {
          return null;
        }
      }
      for (let i = start; i <= max; i++) {
        if (!ranks.includes(i)) {
          return i;
        }
      }
      return max;
    },
    [candidates, getRank, vote, ranks]
  );

  const handleClick = useCallback(
    (c: ICandidate) => {
      const current = getRank(c.id);

      setCandidateRank(
        c.id,
        getNextNumber(current !== null ? current : 1),
        c.label
      );
    },
    [getRank, getNextNumber, vote]
  );

  useEffect(() => {
    console.log("handleClick updated");
  }, [handleClick]);

  if (!candidates) {
    return (
      <Loading source="PreferenceTextInterface" waitingFor={{ candidates }} />
    );
  }

  return (
    <Box>
      <List >
        {candidates.map((c, i) => (
          candidate_display_format === CANDIDATE_DISPLAY_FORMAT.IMAGE_PRIORITY ?
            (<CandidateRowImage
              key={c.id}
              candidate={c}
              rank={getRank(c.id)}
              error={errors[c.id]}
              onChange={handleInput}
              onClick={handleClick}
              index={i}
            />)
            : (<CandidateRowLabel
              key={c.id}
              candidate={c}
              rank={getRank(c.id)}
              error={errors[c.id]}
              onChange={handleInput}
              onClick={handleClick}
              index={i}
            />)


        ))}
        <Divider component="li" />
      </List>
    </Box>
  );
};

interface DisplayCandidateProps {
  candidate: ICandidate
}
const DisplayCandidateName = ({ candidate }: DisplayCandidateProps) => {
  return <ListItemText primary={candidate.label} />
}

interface NumberInputProps {
  index: number;
  value: number | undefined;
}

const NumberInput = ({ value }: NumberInputProps) => {
  return (
    <Typography
      sx={{
        border: "#AAA solid 1px",
        borderRadius: 20,
        width: 30,
        height: 30,
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ":hover": {
          background: "#6ffaff",
        },
      }}
    >
      {value}
    </Typography>
  );
};
