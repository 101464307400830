import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import "./App.css";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { InitializePage } from "./pages/initialize";
import { LoginTokenPage } from "./pages/email-token.page";
import { useContext, useMemo } from "react";
import { AUTH_STATUS, AuthContext } from "./context/auth.provider";
import { Loading } from "./components/loading";
import { ElectionLayout } from "./pages/election";
import { LandingPage } from "./pages/landing-page";
import { Redirect } from "./components/redirect";
import { ReviewPage } from "./pages/election/review";
import { DisputePage } from "./pages/election/dispute";
import { CreateTicket } from "./pages/election/dispute/create-ticket";
import { ApiProvider } from "./context/api.provider";
import { ElectionOverviewPage } from "./pages/election/overview";
import { CONSUMER_TYPE } from "./domain/consumer";
import { BallotPageLayout } from "./pages/election/ballot-page/ballot-page-layout";
import { LabelCacheProvider } from "./context/label-cache.provider";
import { BallotPageVote } from "./pages/election/ballot-page/ballot-page-vote";
import { BallotPageReview } from "./pages/election/ballot-page/ballot-page-review";
import { Logo } from "./components/logo";
import { ElectionSlug } from "./pages/election-slug";

const NotFound = () => {
  return (
    <>
      <Redirect to="/" />
      <Typography>Not found</Typography>
    </>
  );
};
const AuthArea = () => {
  const { status } = useContext(AuthContext);
  if (status !== AUTH_STATUS.AUTHENTICATED) {
    return <div>Not authorised</div>;
  }

  return (
    <ApiProvider>
      <LabelCacheProvider>
        <Outlet />
      </LabelCacheProvider>
    </ApiProvider>
  );
};
const routes = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <NotFound />,
  },
  {
    path: "/e/:organisation_slug/:election_slug/:round_slug",
    element: <ElectionSlug />,
    errorElement: <NotFound />,
  },
  {
    path: "/action-token",
    element: <LoginTokenPage />,
  },
  {
    id: "auth_area",
    path: "",
    element: <AuthArea />,
    children: [
      {
        path: "/",
        element: <InitializePage />,
        id: "landing",
      },
      {
        path: "/login-token",
        element: <LoginTokenPage />,
        id: "login-token",
      },
      {
        path: "/election/:election_id",
        element: <ElectionLayout />,
        id: "election",
        children: [
          {
            index: true,
            element: <ElectionOverviewPage />,
            id: "election-index",
          },
          {
            path: "ballot_page/:ballot_page_id",
            element: <BallotPageLayout />,
            id: "ballot_page",
            children: [
              {
                path: "vote",
                element: <BallotPageVote />,
              },
              {
                path: "review",
                element: <BallotPageReview />,
              },
            ],
          },
          {
            path: "review",
            element: <ReviewPage />,
            id: "review",
          },
          {
            path: "dispute",
            element: <DisputePage />,
            id: "dispute",
          },
          {
            path: "create-ticket",
            element: <CreateTicket />,
          },
        ],
      },
      {
        path: "create-ticket",
        element: <CreateTicket />,
      },
    ],
  },
]);

function App() {
  const { consumer, status } = useContext(AuthContext);
  const preview = useMemo(
    () => consumer && consumer.type !== CONSUMER_TYPE.VOTER,
    [consumer]
  );
  return (
    <>
      <AppBar position="fixed" sx={{ top: 0, background: "#294866" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box height="50px" display="flex" alignItems="center">
            <Logo white />
            <Typography pl={3} variant="h6" color="inherit" noWrap>
              Voting
            </Typography>
          </Box>
          {preview ? (
            <Typography variant="h6" color="inherit" noWrap>
              [PREVIEW]
            </Typography>
          ) : null}
        </Toolbar>
      </AppBar>

      <Container maxWidth="sm" sx={{ paddingTop: "64px" }}>
        <Box sx={{ my: 4 }}>
          {status === AUTH_STATUS.VERIFYING ? (
            <Loading source="PageBase" />
          ) : (
            <RouterProvider router={routes} />
          )}
        </Box>
      </Container>
    </>
  );
}

export default App;
