import { ListItem, Button, ListItemButton, ListItemIcon, TextField, Box, ListItemAvatar, ListItemText, Collapse, Grid, Typography, Divider } from "@mui/material";
import { useState, useCallback } from "react";
import { ApiImage } from "../../../../components/api-image";
import { LightBox } from "../../../../components/light-box";
import { API_BASE } from "../../../../const/config";
import { ICandidate } from "../../../../domain/candidate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CandidateImage } from "./candidate-image";
import Grid2 from "@mui/material/Unstable_Grid2";

interface CandidateRowProps {
    candidate: ICandidate;
    rank: number | null;
    error: boolean;
    onChange: (c: ICandidate, rank: number | null) => void;
    onClick: (c: ICandidate) => void;
    index: number
}
export const CandidateRowLabel = ({
    candidate,
    rank,
    error,
    onChange,
    onClick,
    index
}: CandidateRowProps) => {
    const [open, setOpen] = useState(false);
    const handleInput = useCallback(
        (e: any) => {
            const raw = e.target.value;
            if (raw === "" || raw === null) {
                return onChange(candidate, null);
            }
            let value = Number(raw);
            if (value <= 0) {
                value = 1;
            }

            onChange(candidate, value);
        },
        [candidate, onChange]
    );
    const handleClick = useCallback(() => {
        onClick(candidate);
    }, [candidate, onClick]);
    const handleExpand = useCallback(() => setOpen((o) => !o), [setOpen]);
    return (
        <>
            <Divider component="li" />
            <ListItem
                sx={{
                    cursor: "pointer",
                    padding: "1px 10px",
                    ":hover": {
                        background: "#EEE",
                    },
                }}
                secondaryAction={
                    candidate.statement || candidate.image_lg ? (
                        <Button
                            endIcon={
                                <ExpandMoreIcon
                                    sx={{
                                        transition: "transform ease 0.5s",
                                        transform: open ? "rotate(180deg)" : "rotate(0deg)",
                                    }}
                                />
                            }
                            onClick={handleExpand}
                        >
                            Statement
                        </Button>
                    ) : null
                }
            >
                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                        {/* <NumberInput index={i} value={mapping[c.id]} /> */}
                        <TextField
                            sx={{
                                width: 30,
                                justifyContent: "center",
                                justifyItems: "center",
                                alignContent: "center",
                                alignItems: "center",
                                marginRight: 2,
                                cursor: "pointer",
                            }}
                            value={rank || ""}
                            onChange={handleInput}
                            error={error}
                            size="small"
                            inputProps={{
                                style: {
                                    justifyContent: "center",
                                    justifyItems: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    cursor: "pointer",
                                    textAlign: "center",
                                    padding: 0,
                                },
                            }}
                            onFocus={(event) => {
                                event.target.select();
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary={candidate.label} secondary={candidate.tag_line} />
                </ListItemButton>
            </ListItem>
            {open ? (
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ pl: 4 }}>
                    <Grid container>
                        <Grid item xs={6} sm={4}>
                            {candidate.image_lg ? (
                                <LightBox small={<CandidateImage id={candidate.id} sm />
                                }>
                                    <CandidateImage id={candidate.id} lg />
                                </LightBox>
                            ) : null}
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <Typography>{candidate.statement}</Typography>
                        </Grid>
                    </Grid>
                </Collapse>
            ) : null}
        </>
    );
};


export const CandidateRowImage = ({
    candidate,
    rank,
    error,
    onChange,
    onClick,
}: CandidateRowProps) => {
    const [disableRipple, setDisableRipple] = useState(false);
    const [open, setOpen] = useState(false);
    const handleInput = useCallback(
        (e: any) => {
            const raw = e.target.value;
            if (raw === "" || raw === null) {
                return onChange(candidate, null);
            }
            let value = Number(raw);
            if (value <= 0) {
                value = 1;
            }

            onChange(candidate, value);
        },
        [candidate, onChange]
    );
    const handleClick = useCallback(() => {
        setDisableRipple(false);
        onClick(candidate);
    }, [candidate, onClick]);
    const handleExpand = useCallback(() => setOpen((o) => !o), [setOpen]);
    const handleLightBox = useCallback((open: boolean) => {
        setDisableRipple(open)
    }, [setDisableRipple])

    return (
        <>
            <Divider component="li" />
            <ListItem
                sx={{
                    cursor: "pointer",
                    padding: "1px 10px",
                    ":hover": {
                        background: "#EEE",
                    },

                }}

                secondaryAction={
                    candidate.statement ? (
                        <Button
                            endIcon={
                                <ExpandMoreIcon
                                    sx={{
                                        transition: "transform ease 0.5s",
                                        transform: open ? "rotate(180deg)" : "rotate(0deg)",
                                    }}
                                />
                            }
                            onClick={handleExpand}
                        >
                            Statement
                        </Button>
                    ) : null
                }
            >
                <ListItemButton onClick={handleClick} sx={{
                    flexDirection: 'column'
                }} disableRipple={disableRipple}>
                    <Grid2 container width="100%">
                        <Grid2 sm={6} xs={12}>
                            <Box sx={{ display: 'flex', width: '100%' }}>

                                <ListItemIcon>
                                    {/* <NumberInput index={i} value={mapping[c.id]} /> */}
                                    <TextField
                                        sx={{
                                            width: 30,
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            marginRight: 2,
                                            cursor: "pointer",
                                        }}
                                        value={rank || ""}
                                        onChange={handleInput}
                                        error={error}
                                        size="small"
                                        inputProps={{
                                            style: {
                                                justifyContent: "center",
                                                justifyItems: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                                cursor: "pointer",
                                                textAlign: "center",
                                                padding: 0,
                                            },
                                        }}
                                        onFocus={(event) => {
                                            event.target.select();
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={candidate.label} secondary={candidate.tag_line} />
                            </Box>
                        </Grid2>
                        <Grid2 sm={6} xs={12}>
                            <Box width="100%" height="150px" justifyItems="center" mb={1} mt={2}>

                                <Box maxWidth={400} height="150px">
                                    <ListItemAvatar sx={{ maxWidth: "100%", height: "100%" }}>
                                        <LightBox onLightBox={handleLightBox} small={<CandidateImage id={candidate.id} sm />
                                        }>
                                            <CandidateImage id={candidate.id} lg />
                                        </LightBox>
                                    </ListItemAvatar>
                                </Box>
                            </Box>
                        </Grid2>
                    </Grid2>
                </ListItemButton>


            </ListItem>

            {open ? (
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ pl: 4 }}>
                    <Grid container>
                        <Grid item xs={6} sm={4}>
                            {candidate.image_lg ? (
                                <LightBox small={<CandidateImage id={candidate.id} sm />
                                }>
                                    <CandidateImage id={candidate.id} lg />
                                </LightBox>
                            ) : null}
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <Typography>{candidate.statement}</Typography>
                        </Grid>
                    </Grid>
                </Collapse>
            ) : null}
        </>
    );
};