import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ElectionContext } from "./election.context";
import { ErrorPanel } from "../../components/error-panel";
import { BallotOptInPreference, CANDIDATE_DISPLAY_FORMAT, IBallotResponse } from "../../domain/ballot";
import { RESPONSE_TYPE } from "../../const/vote";
import { sortResult } from "./util";
import { Loading } from "../../components/loading";
import { SortResult } from "../../components/rank-sort/types";
import { AxiosError, AxiosResponse } from "axios";
import { HttpErrorPanel } from "../../components/http-error";
import { HeavyCard } from "../../components/heavy-card";
import { AuthContext } from "../../context/auth.provider";
import { CONSUMER_TYPE } from "../../domain/consumer";
import { ButtonPanel } from "../../components/button-panel";
import { useLabel } from "../../context/label-cache.provider";
import { IBallotPage } from "../../domain/ballot-page";
import { useFetchState } from "../../context/api.provider";
import {
  BallotPageContext,
  BallotPageProvider,
} from "./ballot-page/ballot-page.context";
import { BallotContext, BallotProvider } from "./ballot/ballot.context";
import { CandidateImage } from "./ballot/preference/candidate-image";
import { BallotPageReview } from "./ballot-page/ballot-page-review";
export const ReviewPage = () => {
  const { consumer } = useContext(AuthContext);
  const { ballotPages, submit, election } = useContext(ElectionContext);

  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState<AxiosResponse | AxiosError | null>(null);
  const done = useMemo(() => !!result && result.status === 200, [result]);
  const allowedToSubmit = useMemo(() => {
    return consumer && consumer.type === CONSUMER_TYPE.VOTER;
  }, [consumer]);
  if (!consumer) {
    return <ErrorPanel text="Must have consumer" source="ReviewPage" />;
  }
  if (!election) {
    return <ErrorPanel text="Must have ballots" source="ReviewPage" />;
  }

  const handleSubmit = () => {
    setSubmitting(true);
    setTimeout(() => submit().then((r) => setResult(r)), 200);
  };

  const handleBack = () => {
    setResult(null);
    setSubmitting(false);
  };

  if (result && result.status !== 200 && "response" in result) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item xs={12}>
          <HttpErrorPanel
            text="Submit failed"
            source="ReviewPage"
            axiosResponse={result.response}
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (submitting || done) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Thank you for participating in this election.</Typography>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Loading source="Review" waitingFor={{ Submit: done }} />
          {done ? (
            <Typography
              color="green"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              Submitted!
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Review</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Please review your submission before submitting. After submission you
          will not be able to change your vote.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {ballotPages.map((b) => (
            <Grid item xs={12} key={b.id}>
              <BallotPageProvider ballotPage={b}>
                <BallotPageReview />
              </BallotPageProvider>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ButtonPanel
          color={allowedToSubmit ? undefined : "warning"}
          title={
            allowedToSubmit ? undefined : "Cannot submit votes in preview mode."
          }
          back={{ to: `/election/${election.election_id}` }}
          next={{
            label: "Submit",
            icon: "submit",
            onClick: handleSubmit,
            disabled: !allowedToSubmit,
          }}
        />
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};
