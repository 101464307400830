import { IBallot } from "./ballot";
import { IBallotOptIn } from "./ballot-opt-in";
import { IBallotPage } from "./ballot-page";
import { ICandidate } from "./candidate";


export enum ELECTION_STATUS {
    ACTIVE = "ACTIVE",
    COMPLETE = "COMPLETE",
}

export enum VOTING_ROUND_STATUS {
    PENDING = "PENDING",
    NOMINATIONS = "NOMINATIONS",
    VOTING = "VOTING",
    CLOSED = "CLOSED",
}

export enum ELECTION_MODE {
    SCHEDULE = "SCHEDULE",
    MANUAL = "MANUAL",
}


export interface ApiElection {
    id: string;
    created_at: Date | string;
    organisation_id: string;
    label: string;
    status: ELECTION_STATUS;
    short_description?: string;
    mode: ELECTION_MODE;
    nominations_close_at?: Date | string;
    nominations_open_at?: Date | string;
    voting_close_at: Date | string;
    voting_open_at: Date | string;

}



// the information available to the consumer about the election/voting_round
export interface ApiElectionRound {
    "voting_round_id": string,
    "election_id": string,
    "label": string,
    election_label: string;
    round_label: string;
    election_status: ELECTION_STATUS,
    "round_status": VOTING_ROUND_STATUS,
    "nominations_close_at"?: string | Date | null,
    "nominations_open_at"?: string | Date | null,
    "voting_close_at"?: string | Date | null,
    "voting_open_at"?: string | Date | null,
    "organisation_id": string,
    "short_description": string | null | undefined,
    ballot_pages: {
        [ballot_page_id: string]: ApiBallotPage
    }
}


export interface ApiBallotPage extends IBallotPage {
    opt_in?: IBallotOptIn;
    submitted: boolean;
    ballots: { [ballot_id: string]: ApiIBallot }
}

export interface ApiIBallot extends IBallot {
    candidates: { [candidate_id: string]: ICandidate }
}