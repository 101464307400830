import { IAdminUser } from "./admin-user";
import { ICandidate } from "./candidate";
import { IVoter } from "./voter";


export enum CONSUMER_TYPE {
    VOTER = "VOTER",
    ADMIN_USER = "ADMIN_USER",
    CANDIDATE = "CANDIDATE",
    ANONYMOUS = "ANONYMOUS"
}


export interface IViewerBase {
    id?: string;
    label: string;
    type: CONSUMER_TYPE;
    election_id?: string;
    voting_round_id: string
}

export interface IViewerVoter extends IViewerBase {
    id: string;
    type: CONSUMER_TYPE.VOTER;
    voter: IVoter;

}
export interface IViewerAdminUser extends IViewerBase {
    id: string;
    type: CONSUMER_TYPE.ADMIN_USER;
    admin_user: IAdminUser
}
export interface IViewerCandidate extends IViewerBase {
    id: string;
    type: CONSUMER_TYPE.CANDIDATE;
    candidate: ICandidate;
}
export interface IViewerAnonymous extends IViewerBase {
    type: CONSUMER_TYPE.ANONYMOUS;
}