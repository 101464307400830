import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useFetchState } from "../context/api.provider";
import { HeavyCard } from "../components/heavy-card";
import { staticFetch } from "../util/api";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../components/loading";
import { ApiElectionRound } from "../domain/election";
import { formateDate } from "./election/util";
import { FormContext } from "../components/simple-form/util";
import { AuthContext } from "../context/auth.provider";
import { read } from "fs";

export const ElectionSlug = () => {
  const { organisation_slug, election_slug, round_slug } = useParams();
  const [election, setElection] = useState<any>();
  useEffect(() => {
    staticFetch({
      url: `/election/${organisation_slug}/${election_slug}/${round_slug}`,
      method: "get",
    }).then((res) => setElection(res.data));
  }, [organisation_slug, election_slug, round_slug]);
  if (!organisation_slug || !election_slug || !round_slug) {
    return <span>Slug required</span>
  }
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        {election ? (
          <VoterAccessCodeForm
            election={election}
            organisation_slug={organisation_slug}
            election_slug={election_slug}
            round_slug={round_slug}
          />
        ) : (
          <Loading source={"ElectionSlug"} />
        )}
      </Grid>
    </Grid>
  );
};


interface VoterAccessCodeFormProps {
  election: ApiElectionRound;
  organisation_slug: string;
  election_slug: string;
  round_slug: string;
}
const VoterAccessCodeForm = ({ election,
  organisation_slug,
  election_slug,
  round_slug
}: VoterAccessCodeFormProps) => {
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);
  const [form, setForm] = useState<any>({
    unique_id: "",
    access_code: "",
  });
  const { election_label, round_label, voting_open_at, voting_close_at } = election;
  const [error, setError] = useState();
  const handleLogin = useCallback(() => {
    staticFetch({
      url: `/election/${organisation_slug}/${election_slug}/${round_slug}`,
      method: "post",
      data: form
    }).then(res => {
      setToken(res.data.api_token);
      navigate(`/election/${res.data.api_token.election_id}`)
    }).catch(setError)
  }, [form]);
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">{election.label}: {round_label}</Typography>
          <Typography >{election.round_status}</Typography>
          <hr />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption">
            Opens:
          </Typography>
          {voting_open_at ? (
            <Typography>
              {formateDate(voting_open_at)}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption">
            Closes:
          </Typography>
          {voting_close_at ? (
            <Typography>
              {formateDate(voting_close_at)}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"center"}>

            <HeavyCard sx={{ mt: 8, maxWidth: 300, xs: { p: 3 }, md: { p: 4 } }}>

              <Typography variant="h6" mt={-1} mb={2}>Voter Login</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Unique ID"
                    fullWidth
                    value={form.unique_id}
                    onChange={(e) => setForm((f: any) => ({ ...f, unique_id: e.target.value }))} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Access Code"
                    fullWidth
                    value={form.access_code}
                    onChange={(e) => setForm((f: any) => ({ ...f, access_code: e.target.value }))} />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
                </Grid>

              </Grid>
            </HeavyCard>
            {error ? JSON.stringify(error, null, 2) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}