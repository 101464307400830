import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { createContext } from "react";
import {
  IBallotResponse,
  IBallot,
  Vote,
  validateVote,
} from "../../../domain/ballot";
import { AuthContext } from "../../../context/auth.provider";
import { ApiContext, useFetchState } from "../../../context/api.provider";
import { Loading } from "../../../components/loading";
import { BallotPageContext } from "../ballot-page/ballot-page.context";
import { RESPONSE_TYPE } from "../../../const/vote";
import { ICandidate, ICandidateSorted } from "../../../domain/candidate";
import { ApiIBallot } from "../../../domain/election";


export interface SubmitResult {
  success: boolean;
  message?: string;
  code: string;
}

export interface IBallotContext {
  ballot: IBallot;
  ballotResponse: IBallotResponse | undefined;
  setBallotVote: (value: React.SetStateAction<Vote>) => void;
  setCandidateRank: (id: string, rank: number | null, label: string) => void;
  candidates: Array<ICandidate> | undefined;
  minRank: number;
  maxRank: number;
}

export const BallotContext = createContext<IBallotContext>({
  ballot: {} as any,
  ballotResponse: undefined,
  setBallotVote: (value: React.SetStateAction<Vote>) => null,
  setCandidateRank: (id: string, rank: number | null, label: string) => null,
  candidates: [],
  minRank: 0,
  maxRank: 1,
});
interface BallotProviderProps extends PropsWithChildren {
  ballot: ApiIBallot;
}
export const BallotProvider = ({ children, ballot }: BallotProviderProps) => {
  const { setBallotResponse, ballotResponses } = useContext(BallotPageContext);
  const ballotResponse: IBallotResponse | undefined =
    ballotResponses[ballot.id];

  const { min_ranking, max_ranking } = ballot;
  const candidates = useMemo(() => {
    const { shuffle_candidates } = ballot;
    const _candidates = Object.values(ballot.candidates);
    if (shuffle_candidates) {
      return _candidates
        .map(
          (c: ICandidate): ICandidateSorted => ({
            ...c,
            display_order: Math.random() * 10000,
          })
        )
        .sort((a: ICandidateSorted, b: ICandidateSorted) =>
          a.display_order > b.display_order ? -1 : 1
        );
    } else {
      return _candidates
        .map(
          (c: ICandidate): ICandidateSorted => ({
            ...c,
            display_order: Math.random() * 10000,
          })
        )
        .sort((a: ICandidateSorted, b: ICandidateSorted) =>
          a.display_order > b.display_order ? -1 : 1
        );
    }
  }, [ballot]);

  const setBallotVote = useCallback(
    (value: React.SetStateAction<Vote>) => {
      setBallotResponse(ballot.id, (response) => {
        const _value = typeof value === "function" ? value(response) : value;

        return {
          ..._value,
          ballot_id: ballot.id,
          validation: validateVote(ballot, _value),
        };
      });
    },
    [setBallotResponse, ballot]
  );

  const setCandidateRank = useCallback(
    (id: string, rank: number | null, label: string) => {
      setBallotVote((br: Vote): Vote => {
        const preferenceVote =
          br.type === RESPONSE_TYPE.PREFERENCE ? br.vote : {};

        return {
          type: RESPONSE_TYPE.PREFERENCE,
          vote: {
            ...preferenceVote,
            [id]: {
              id,
              index: rank,
              label: label,
            },
          },
        };
      });
    },
    [setBallotVote]
  );

  const minRank = useMemo(() => {
    if (min_ranking !== undefined && min_ranking !== null) return min_ranking;
    return 1;
  }, [min_ranking]);

  const maxRank = useMemo(() => {
    if (max_ranking !== undefined && max_ranking !== null) return max_ranking;
    return candidates ? candidates.length : 1;
  }, [max_ranking, candidates]);

  if (ballot.response_type !== RESPONSE_TYPE.YES_NO && !candidates) {
    return <Loading source="BallotProvider" />;
  }

  return (
    <BallotContext.Provider
      value={{
        ballot,
        candidates: candidates,
        ballotResponse: ballotResponse,
        setBallotVote,
        setCandidateRank,
        minRank,
        maxRank,
      }}
    >
      {children}
    </BallotContext.Provider>
  );
};

const ballotIsComplete = (ballot: IBallot, response?: IBallotResponse) => {
  if (!response) {
    return false;
  }

  if (response.vote && response.validation) {
    return true;
  }

  return false;
};
